// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TypeOption_typeOption__V18hb image:hover {\n    filter: invert(47%) sepia(41%) saturate(569%) hue-rotate(165deg) brightness(94%) contrast(97%);\n    /* transition: all 0.2s ease; */\n    /* border: 0; */\n}\n\n.TypeOption_typeOption__V18hb div {\n    display: flex;\n    margin: 5px 10px 5px 0;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/main/typeOption/TypeOption.module.css"],"names":[],"mappings":"AAAA;IACI,8FAA8F;IAC9F,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".typeOption image:hover {\n    filter: invert(47%) sepia(41%) saturate(569%) hue-rotate(165deg) brightness(94%) contrast(97%);\n    /* transition: all 0.2s ease; */\n    /* border: 0; */\n}\n\n.typeOption div {\n    display: flex;\n    margin: 5px 10px 5px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typeOption": "TypeOption_typeOption__V18hb"
};
export default ___CSS_LOADER_EXPORT___;
