import React, { useRef } from "react";
import classes from './InputWithPlaceholder.module.css'
import 'colors.css'
import 'styles/css/general.css'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useDeviceType } from "components/hooks/useDeviceType";

interface Props {
    marginTop?: boolean,
    value: any,
    setValue: (params: any) => any,
    valueKey?: string,
    placeholder?: string,
    example?: string,
    containerStyle?: object,
    inputStyle?: object,
    slider?: boolean,
    max?: number,
    min?: number,
}

function InputWithPlaceholder({ max = 500, min = 0, marginTop = true, value, setValue, valueKey, placeholder, example = '', containerStyle, inputStyle, slider = false }: Props) {

    const ref = useRef(null)
    const focus = () => ref.current.focus()
    const onChange = (event) => valueKey ? setValue(old => ({ ...old, [valueKey]: event.target.value })) : setValue(parseInt(event.target.value))
    const onChangeSlider = (val) => setValue(old => ({ ...old, [valueKey]: val }))

    const deviceType = useDeviceType()


    return (
        <div className={classes.wrapper} style={{ marginTop: marginTop ? '0.5%' : 0, ...containerStyle }} onClick={focus}>
            {valueKey
                ? (value[valueKey].length > 0) && (typeof value[valueKey] === 'string' || value[valueKey] > -1) && <h6>{placeholder}</h6>
                : (value.length > 0) && (typeof value === 'string' || value > -1) && <h6>{placeholder}</h6>
            }
            {valueKey
                ? (<input ref={ref} type="text" value={(typeof value[valueKey] === 'string' || value[valueKey] > -1)
                    ? value[valueKey] : ''} onChange={onChange} className='input' style={{ ...inputStyle }} placeholder={`${placeholder}: ${example}`} />)
                : (<input ref={ref} type="text" value={(typeof value === 'string' || value > -1)
                    ? value : ''} onChange={onChange} className='input' style={{ ...inputStyle }} placeholder={`${placeholder}: ${example}`} />)
            }

            {(typeof value[valueKey] === "number" || (!value[valueKey] && slider)) &&
                (valueKey
                    ? <Slider
                        value={value[valueKey] === -1 ? parseInt(example) : value[valueKey]}
                        onChange={onChangeSlider}
                        max={max}
                        min={min}
                        className={classes.slider}
                        // полоска до
                        trackStyle={{ background: 'linear-gradient(90deg, rgba(6, 1, 5, 1) 5%, rgb(148, 3, 95) 55%, rgba(240, 37, 73, 1) 85%)' }}
                        // полоска после
                        railStyle={{ background: 'linear-gradient(90deg,rgba(240, 37, 73, 1) 0%, rgba(224, 224, 224, 1) 99%)' }}
                        // ручка
                        handleStyle={{
                            backgroundColor: 'var(--color-black)', width: '2.5vh', height: '2.5vh',
                            marginTop: deviceType === 'desktop' ? '-1.1vh' : '-0.7vh', opacity: '0.9'
                        }}
                    />
                    : <Slider
                        value={value === -1 ? parseInt(example) : value}
                        onChange={setValue}
                        max={max}
                        min={min}
                        className={classes.slider}
                        // полоска до
                        trackStyle={{ background: 'linear-gradient(90deg, rgba(6, 1, 5, 1) 5%, rgb(148, 3, 95) 55%, rgba(240, 37, 73, 1) 85%)' }}
                        // полоска после
                        railStyle={{ background: 'linear-gradient(90deg,rgba(240, 37, 73, 1) 0%, rgba(224, 224, 224, 1) 99%)' }}
                        // ручка
                        handleStyle={{
                            backgroundColor: 'var(--color-black)', width: '2.5vh', height: '2.5vh',
                            marginTop: deviceType === 'desktop' ? '-1.1vh' : '-0.7vh', opacity: '0.9'
                        }}
                    />)}
        </div>
    );
}

export default InputWithPlaceholder;