export interface ColorRGBA { r: number, g: number, b: number, a: GLfloat }

class Formatter {

    static rgbaToHex({ r, g, b, a }: ColorRGBA) {
        /**
         * Функция из RGBA делает HEXA формат
         */
        // Убедимся, что значения RGB находятся в диапазоне от 0 до 255
        r = Math.max(0, Math.min(255, r));
        g = Math.max(0, Math.min(255, g));
        b = Math.max(0, Math.min(255, b));

        // Убедимся, что значение A находится в диапазоне от 0 до 1
        a = Math.max(0, Math.min(1, a));

        // Преобразуем значения RGB в шестнадцатеричный формат
        let hexR = r.toString(16).padStart(2, '0');
        let hexG = g.toString(16).padStart(2, '0');
        let hexB = b.toString(16).padStart(2, '0');

        // Преобразуем альфа-канал в шестнадцатеричный формат
        let hexA = Math.round(a * 255).toString(16).padStart(2, '0');

        // Возвращаем объединенную строку в формате HEXA
        return `${hexR}${hexG}${hexB}${hexA}`;
    }
}

export default Formatter