import { configureStore } from "@reduxjs/toolkit";

import cameraSlice from "./slice/cameraSlice"
import loaderSlice from "./slice/loaderSlice"


export default configureStore({
    reducer: {
        camera: cameraSlice,
        loader: loaderSlice,
    },
})