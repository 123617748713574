import MainPage from "pages/public/MainPage";

export const adminRoutNames = {
    // createOccasion: 'create-event',
}

export const publickRoutNames = {
    nofound: 'nofound',
    main: 'main',
}

export const privateRoutNames = {
    // grade: 'grade',
}

export const publickRoutes = [
    { path: `/${publickRoutNames.main}`, component: MainPage, exact: true },
]

export const privateRoutes = [
    // { path: `/${privateRoutNames.personalData}`, component: PersonalDataPage, exact: true },
]

export const adminRoutes = [
    // { path: `/${adminRoutNames.createOccasion}`, component: CreateOccasionPage, exact: true },
]