import React from "react";
import classes from './InfoContainer.module.css'

function InfoContainer() {
    return (
        <div>
            <h1 className={classes.h1}>QRTrace</h1>
            <h2 className={classes.h2}>Отслеживание статистику переходов по ссылкам</h2>
            <h2 className={classes.h2}>Генератор красивых QR кодов со своим уникальным стилем</h2>
        </div>
    );
}

export default InfoContainer;