import React, { useState } from 'react';
import classes from './ImageBackgroundSwitcher.module.css'
interface Props {
    value: any,
    setValue: (params: any) => any
}

const ImageBackgroundSwitcher = ({ value, setValue }: Props) => {

    const handleToggle = () => {
        const newImageSize = { ...value }
        newImageSize.imageOptions.hideBackgroundDots = !value.imageOptions.hideBackgroundDots
        setValue(newImageSize);
    };


    return (
        <div className={classes.wrapper}>
            <div className={classes.titleStyle}>
                <h4>Background Logo</h4>
            </div>
            <button className={classes.buttonStyle} onClick={handleToggle}>
                {value.imageOptions.hideBackgroundDots ? `Filled` : `Transparent`}
            </button>
        </div>
    );
};

export default ImageBackgroundSwitcher;
