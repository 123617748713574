import React from "react";
import './OptionTitle.module.css'
import 'colors.css'
import 'styles/css/general.css'

interface Props {
    title: string;
    onClick?: ((params: any) => any) | undefined;
}

function OptionTitle({ title = '', onClick }: Props) {
    return (
        <div className="optionTitle" onClick={onClick}>
            <h4>{title}</h4>
        </div>
    );
}

export default OptionTitle;