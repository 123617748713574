// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Svg_cls1__I4ZUc {\n    font-size: 3vw;\n    /* font-size: 2.5vw; */\n    fill: #e0e0e0;\n    font-family: \"Imprint MT Shadow\";\n    text-anchor: middle;\n}\n\n.Svg_cls1__I4ZUc:hover {\n    fill: var(--color-red);\n}\n\n\n@media (max-width: 1920px) {\n    .Svg_cls1__I4ZUc {\n        font-size: 4vw;\n    }\n}\n\n@media (max-width: 1700px) {\n    .Svg_cls1__I4ZUc {\n        font-size: 5vw;\n    }\n}\n\n@media (max-width: 1280px) {\n    .Svg_cls1__I4ZUc {\n        font-size: 6vw;\n    }\n}\n\n@media (max-width: 770px) {\n    .Svg_cls1__I4ZUc {\n        font-size: 32vw;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/UI/svg/Svg.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,sBAAsB;IACtB,aAAa;IACb,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;;AAGA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".cls1 {\n    font-size: 3vw;\n    /* font-size: 2.5vw; */\n    fill: #e0e0e0;\n    font-family: \"Imprint MT Shadow\";\n    text-anchor: middle;\n}\n\n.cls1:hover {\n    fill: var(--color-red);\n}\n\n\n@media (max-width: 1920px) {\n    .cls1 {\n        font-size: 4vw;\n    }\n}\n\n@media (max-width: 1700px) {\n    .cls1 {\n        font-size: 5vw;\n    }\n}\n\n@media (max-width: 1280px) {\n    .cls1 {\n        font-size: 6vw;\n    }\n}\n\n@media (max-width: 770px) {\n    .cls1 {\n        font-size: 32vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Svg_cls1__I4ZUc"
};
export default ___CSS_LOADER_EXPORT___;
