import React, { useEffect, useRef, } from "react";

interface Props {
    children: React.ReactNode;
    setRefMainOptions?: ((param: any) => any) | undefined;
}

function DropDownContainer({ children, setRefMainOptions }: Props) {
    const ref = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setRefMainOptions && setRefMainOptions(ref)
    }, [])

    return (
        <div ref={ref} className={`${setRefMainOptions ? 'dropDownContainer' : 'dropDownContainer open'} `}>
            {children}
        </div>
    );
}

export default DropDownContainer;