const Strings = {
    /*  Occasion */
    eventTitle: "Название мероприятия",
    eventDate: "Дата проведения",
    eventTime: "Время",
    eventButtonText: "Участвовать",
    /*  Sidebar */
    sidebarTitle1: "СурГУ",
    sidebarTitle2: "Мероприятия",
    sidebarMain: "Главная",
    sidebarOccasion: "Мероприятия",
    sidebarCase: "Кейсы",
    sidebarSupport: "Контакты",
    sidebarExit: "Выход",
    sidebarEnter: "Вход",
    sidebarAdminPanel: "Админ панель",
    sidebarGrade: "Оценка команд",
    sidebarProfile: "Профиль",
    /*  TeamRoom */
    teamRoomDesk0: "Столов больше нет, обратитесь к организатору!",
    /*  NavBar */
    navbarCreate: "Создание мероприятия",
    navbarReport: "Выгрузка отчета",
    navbarChangePart: "Назначение ролей",
    navbarNews: "Создание новости",
    navbarAdministration: "Администрирование",
    navbarWorkOnCase: "Работа с кейсами",
    navbarCertificate: "Сертификаты",
    navbarGrade: "Оценка команд",
    /*  CaseContainer */
    caseRedText: "Сообщение!",
    caseText: "Кейсы появляются автоматически во время проведения мероприятия, посетите раздел мероприятия и узнайте, что скоро состоится.",
    occasionText: "На данный момент нет активных мероприятий, ожидайте ближайших новостей",
    /*  Loader */
    loaderText: "Загрузка",
    /* Profile */
    navbarProfilCheckPoint: "Check Point",
    navbarProfilFinalMark: "Финальная оценка",
    navbarInfoAboutCommand: "Об команде",
    navbarProfileData: "Личные данные",
    navbarProfileOccasion: "Мероприятия",
    /* Notifications */
    notificationsInfoTitle: "Оповещение",
    notificationsSuccessTitle: "Успешно",
    notificationsWarningTitle: "Предупреждение",
    notificationsErrorTitle: "Ошибка",
    notificationsCopyQRCode: 'QR код скопированы в буфер',
    notificationsCopyData: 'Данные скопированы в буфер',
    notificationsClipboardData: 'Данные из буфер, получены',
    notificationsDataUpdate: "Операция прошла успешно",
    notificationsLeaveCommand: "Вы успешно покинули команду",
    notificationsReportCreate: "Отчет успешно сгенерирован",
    notificationsReportCreateCopyLinkToBuffer: "Ссылка на отчет скопирована в буфер обмена",
    notificationsCertificateGenerated: "Сертификат успешно сгенерирован",
    notificationsRemoveImage: "Изображение удалено",
    notificationsAddImage: "Изображение добавлено",
    notificationsWarningReadBuffer: "Не удалось прочитать содержимое буфера обмена: ",
    notificationsAddNews: "Новость создана",
    notificationsWarningDataEmptyNews: "Заполните все поля!",
    /* Profile */
    name: "Имя",
    /* Portfolio */
    portfolioText: "У вас еще нет завершенных мероприятия",
    /* Create Case*/
    createCase: "Создание кейса",
    /* Team Room */
    teamRoom: "Комната команды",
    /* AdministrationPage */
    administrationPage: {
        currentOccasion: { active: "Активно", inActive: "Завершено" },
        currentOccasionButton: { active: "Начать", inActive: "Завершить" },
        currentOccasionTask: { active: "Опубликовано", inActive: "Не опубликовано" },
        currentOccasionTaskButton: { active: "Опубликовать", inActive: "Снять" },
        currentNewsButton: { active: "Опубликовать", inActive: "Снять" },
        currentNews: { active: "Опубликовано", inActive: "Снято с публикации" },
    },
}

export default Strings