const Colors = {
    white: "#FFFFFF",
    gray: "#808080",
    blue: "#0075FF",
    red: "#FF3636",
    gray2: "#BCBCBC",
    blue2: "#3F98FF",
    violet: '#752c5b',
    markRed: "#bd362f",
    markOrange: "#f89406",
    markYellow: "#FCD100",
    markGreen: "#51a351",

    // NEW
    black: "rgb(51, 51, 51)",
}

export default Colors

// https://www.youtube.com/watch?v=2-Iex4XG_Zg&t=3s