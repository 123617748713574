import React, { useEffect, useRef, useState } from "react";
import classes from './DragAndDropContainer.module.css'
import { BsImage } from 'react-icons/bs';
import { FaFileUpload } from 'react-icons/fa';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import InputWithPlaceholder from "../input/InputWithPlaceholder";
import { vhp } from "values/Values";
import ImageBackgroundSwitcher from "./ImageBackgroundSwitcher";


interface Props {
    value: any,
    setValue: (params: any) => any
}

function DragAndDropContainer({ value, setValue }: Props) {
    const wrapperRef = useRef(null);


    const handleDrop = (item) => {
        if (item.files && item.files.length) {
            const file = item.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                setValue(old => ({ ...old, image: reader.result }));
            };
            reader.readAsDataURL(file);
        }
    };

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop: (item) => handleDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = canDrop && isOver;
    const icon = isActive ? <FaFileUpload /> : <BsImage />;
    const placeholder = isActive ? 'Drop' : 'Logo';

    const getImageSize = () => (value.imageOptions.imageSize * 100)
    const setImageSize = (val) => {
        const newImageSize = { ...value }
        newImageSize.imageOptions.imageSize = val / 100
        setValue(newImageSize);
    }

    const getImageMargin = () => value.imageOptions.margin
    const setImageMargin = (val) => {
        const newImageSize = { ...value }
        newImageSize.imageOptions.margin = val
        setValue(newImageSize);
    }

    const imageSizeProps = {
        placeholder: 'Image Size',
        example: '50',
        value: getImageSize(),
        setValue: setImageSize,
        slider: true,
        max: 100,
    }
    const imageMarginProps = {
        placeholder: 'Image Margin',
        example: '1',
        value: getImageMargin(),
        setValue: setImageMargin,
        slider: true,
        max: 50,
    }

    const fileInputRef = useRef(null);

    const handleClickLogo = () => {
        // Simulate a click on the hidden file input element
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className={classes.wrapper} ref={drop}>
            <div className={classes.container} onClick={handleClickLogo}>
                <div>
                    <h3>{placeholder}</h3>
                </div>
                <div>{icon}</div>
                {/* Hidden input element to handle file selection */}
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleDrop(e.target)}
                />
            </div>
            <InputWithPlaceholder {...imageSizeProps}
                inputStyle={{
                    fontWeight: '700',
                    color: 'var(--color-black)',
                    textAlign: 'center',
                    backgroundColor: 'var(--color-gray)',
                    marginBottom: vhp(0.5)
                }}
                containerStyle={{ backgroundColor: 'var(--color-gray)', marginTop: vhp(0.5) }}
            />
            <InputWithPlaceholder {...imageMarginProps}
                inputStyle={{
                    fontWeight: '700',
                    color: 'var(--color-black)',
                    textAlign: 'center',
                    backgroundColor: 'var(--color-gray)',
                    marginBottom: vhp(0.5)
                }}
                containerStyle={{ backgroundColor: 'var(--color-gray)', marginTop: vhp(0.5) }}
            />
            <ImageBackgroundSwitcher value={value} setValue={setValue} />
        </div>
    );
}

export default DragAndDropContainer;