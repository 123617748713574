import React from 'react'
import SvgSelector from '../svg/SvgSelector';
import classes from './NavBar.module.css'
import 'colors.css'

function NavBar() {


    return (
        // <div className={classes.wrapper}>
        <div className={classes.container}>
            <div className={classes.leftContainer}>
                <h1>QRTrace</h1>
                {/* <SvgSelector type='logo' /> */}
            </div>
            <div className={classes.rightContainer}>
                <SvgSelector type='edro' />
            </div>
        </div>
        // {/* </div> */ }
    );
}

export default NavBar;