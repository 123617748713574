import { Navigate, Routes, Route } from "react-router-dom";
import { adminRoutes } from "router";
import OAuthService from "API/OAuthService";

function AppRouterAdmin({ children }) {
    const auth = true
    const userInfo = OAuthService.decryptedSync('userData')


    if (!auth) {
        return <Navigate to='/main' state={{ auth: true }} />
    }


    return (<Routes>
        {
            ((userInfo?.user) || userInfo?.superUser === true) &&
            adminRoutes.map(rout => <Route path={rout.path} element={<rout.component />} key={rout.path} />)
        }
        <Route path="*" element={<Navigate to="/nofound" />} />
    </Routes>
    )
}

export default AppRouterAdmin;