import React from 'react'
// import AppLoader from "components/UI/appLoader/AppLoader";
// import Notifications from "components/UI/popupNotifications/Notifications";
// import { useDeviceType } from "components/hooks/useDeviceType";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import QrScanner from "./components/UI/qrCode/qrScanner/QrScanner";
import Colors from "values/Colors";
import AppRouter from "components/appRouter/AppRouter";
import RootState from 'redux/store'
import NavBar from 'components/UI/navbar/NavBar';
import 'styles/css/App.css'
import 'colors.css'
import 'values.css'



function App() {
    const cameraStatus = useSelector((state: RootState) => state.camera.isCamera)


    return (
        // <AppLoader status={loadingOAuth}>
        <BrowserRouter>
            {cameraStatus === true ?
                <QrScanner />
                :
                <div className="wrapper">
                    <nav className="navbar">
                        <NavBar />
                    </nav>

                    <div className='content'>
                        <div className="content__container">
                            <AppRouter />
                        </div>
                    </div>

                    <div className='footer-container'>
                        <nav className="footer">
                            {/* <Footer /> */}
                        </nav>
                    </div>
                </div>
            }
        </BrowserRouter>
        // </AppLoader>
    );
}

export default App;
