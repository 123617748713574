// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageBackgroundSwitcher_buttonStyle__WDKE4 {\n    padding: 10px;\n    border: none;\n    border-radius: 12px;\n    cursor: pointer;\n    background: var(--color-black);\n    color: var(--color-white);\n    margin-top: 0.5vh;\n}\n\n\n.ImageBackgroundSwitcher_titleStyle__E\\+CEq h4 {\n    margin: 0;\n    padding: 0;\n}\n\n.ImageBackgroundSwitcher_wrapper__o5bYV {\n    display: grid;\n    justify-content: center;\n    margin-top: 1vh;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/main/dragAndDrop/ImageBackgroundSwitcher.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,8BAA8B;IAC9B,yBAAyB;IACzB,iBAAiB;AACrB;;;AAGA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".buttonStyle {\n    padding: 10px;\n    border: none;\n    border-radius: 12px;\n    cursor: pointer;\n    background: var(--color-black);\n    color: var(--color-white);\n    margin-top: 0.5vh;\n}\n\n\n.titleStyle h4 {\n    margin: 0;\n    padding: 0;\n}\n\n.wrapper {\n    display: grid;\n    justify-content: center;\n    margin-top: 1vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonStyle": "ImageBackgroundSwitcher_buttonStyle__WDKE4",
	"titleStyle": "ImageBackgroundSwitcher_titleStyle__E+CEq",
	"wrapper": "ImageBackgroundSwitcher_wrapper__o5bYV"
};
export default ___CSS_LOADER_EXPORT___;
