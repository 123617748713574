import React from "react";
import classes from './TypeOption.module.css'
import scrollClasses from 'components/UI/main/color/ColorPicker.module.css'
import SvgTypeOption from "components/UI/svg/SvgTypeOption";



interface Props {
    value: object,
    setValue: (pamas: any) => any
}

function TypeOption({ value, setValue, }: Props) {

    const activeFilter = 'invert(47%) sepia(41%) saturate(569%) hue-rotate(165deg) brightness(94%) contrast(97%)'
    const defaultFilter = 'invert(17%) sepia(1%) saturate(0%) hue-rotate(207deg) brightness(100%) contrast(90%)'
    const changeType = (val, key) => {
        const newData = { ...value }
        newData[key].type = val
        setValue(newData)
    }

    const getColor = (val, key) => {
        return (val === value[key].type) ? activeFilter : defaultFilter
    }


    return (
        <div className={classes.typeOption} >
            <div className={scrollClasses.horizontalScrollContainer} style={{ width: '100%' }}>
                <div onClick={() => changeType('', 'dotColors')}>
                    <SvgTypeOption dotType='none' filter={getColor('', 'dotColors')} />
                </div>
                <div onClick={() => changeType('dot', 'dotColors')}>
                    <SvgTypeOption dotType='dot' filter={getColor('dot', 'dotColors')} />
                </div>
                <div onClick={() => changeType('square', 'dotColors')}>
                    <SvgTypeOption dotType='square' filter={getColor('square', 'dotColors')} />
                </div>
            </div>
            <div className={scrollClasses.horizontalScrollContainer} style={{ width: '100%' }}>
                <div onClick={() => changeType('', 'squareColors')}>
                    <SvgTypeOption squareType='none' filter={getColor('', 'squareColors')} />
                </div>
                <div onClick={() => changeType('dot', 'squareColors')}>
                    <SvgTypeOption squareType='dot' filter={getColor('dot', 'squareColors')} />
                </div>
                <div onClick={() => changeType('extra-rounded', 'squareColors')}>
                    <SvgTypeOption squareType='extraRounded' filter={getColor('extra-rounded', 'squareColors')} />
                </div>
                <div onClick={() => changeType('square', 'squareColors')}>
                    <SvgTypeOption squareType='square' filter={getColor('square', 'squareColors')} />
                </div>
            </div>
            <div className={scrollClasses.horizontalScrollContainer} style={{ width: '100%' }}>
                <div onClick={() => changeType('classy', 'dotsOptions')}>
                    <SvgTypeOption dotsType='classy' filter={getColor('classy', 'dotsOptions')} />
                </div>
                <div onClick={() => changeType('classy-rounded', 'dotsOptions')}>
                    <SvgTypeOption dotsType='classyRounded' filter={getColor('classy-rounded', 'dotsOptions')} />
                </div>
                <div onClick={() => changeType('dots', 'dotsOptions')}>
                    <SvgTypeOption dotsType='dots' filter={getColor('dots', 'dotsOptions')} />
                </div>
                <div onClick={() => changeType('extra-rounded', 'dotsOptions')}>
                    <SvgTypeOption dotsType='extraRounded' filter={getColor('extra-rounded', 'dotsOptions')} />
                </div>
                <div onClick={() => changeType('rounded', 'dotsOptions')}>
                    <SvgTypeOption dotsType='rounded' filter={getColor('rounded', 'dotsOptions')} />
                </div>
                <div onClick={() => changeType('square', 'dotsOptions')}>
                    <SvgTypeOption dotsType='square' filter={getColor('square', 'dotsOptions')} />
                </div>
            </div>
        </div>
    )
}

export default TypeOption