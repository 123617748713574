import classes from './QrGenerator.module.css'
// import CaseContainer from 'components/UI/caseContainer/CaseContainer';
import { useDispatch } from 'react-redux';
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import React, { useEffect, useRef, useState } from "react";
import QrCode from './QrCode'
import Notifications from "components/UI/popupNotifications/Notifications";
import { NotificationManager } from 'react-notifications';
import Strings from 'values/Strings';
import 'styles/css/general.css'
import 'values.css'
import 'colors.css'
import QRCodeStyling from 'qr-code-styling';
import * as htmlToImage from 'html-to-image';
import CopyParamsButton from './button/CopyParamsButton';
import { useDeviceType } from 'components/hooks/useDeviceType';

const percent = 15
const defaultSize = (window.screen.width * ((window.screen.width > 767) ? percent : (percent * 2)) / 100)

const defaultOptions = {
    url: 'https://www.QRTrace.ru',
    qrCodeSize: defaultSize,
    dotColors: 'var(--color-black)',
    pointColors: 'var(--color-black)',
}


interface Props {
    url?: string
    backgroundColor?: Object
    dotsOptions?: Object
    squareColors?: Object
    dotColors?: Object
    imageOptions?: Object
    qrCodeSize?: number
    image?: string
    copyParamsInBuffer?: (params: any) => void,
    pastParamsInBuffer?: (params: any) => void,
    downloadParamsFile?: (params: any) => void,
}


const QrGenerator = ({
    url,
    qrCodeSize,
    backgroundColor,
    dotsOptions,
    squareColors,
    dotColors,
    image,
    copyParamsInBuffer,
    imageOptions,
    pastParamsInBuffer,
    downloadParamsFile,
}: Props) => {
    (qrCodeSize === -1) && (qrCodeSize = defaultOptions.qrCodeSize);
    (url.length === 0) && (url = defaultOptions.url);

    const onEmpty = (obj) => {
        (obj.gradient.colorStops.length === 0) && obj.gradient.colorStops.push({ offset: 0.3, color: 'rgba(255,255,255,0)' })
        return obj
    }

    const checkImageSize = (obj) => {
        (obj.imageSize === 0) && (obj.imageSize = 0.01)
        return obj
    }


    const options = {
        data: url,
        // width: vhwp(500),
        // height: vhwp(500),
        // width: qrCodeSize,
        // height: qrCodeSize,
        backgroundOptions: onEmpty(backgroundColor),
        dotsOptions: onEmpty(dotsOptions),
        cornersSquareOptions: onEmpty(squareColors),
        cornersDotOptions: onEmpty(dotColors),
        image,
        imageOptions: checkImageSize(imageOptions),
    }

    const dispatch = useDispatch()
    const ref = useRef(null);
    const device = useDeviceType()

    useEffect(() => {
        dispatch(changeLoaderStatus(false))
        QrCode.append(ref.current);
    }, []);


    useEffect(() => {
        QrCode.update({
            ...options,
        });
    }, [Object.values(options)]);

    const downloadQRCode = () => {
        const qrCode = new QRCodeStyling({
            ...options,
            width: qrCodeSize,
            height: qrCodeSize,
        });
        qrCode.download({
            name: 'QRTrace',
        });
    };

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsWarningTitle, 5000);
        }
    }

    const copyImageToClipboard = () => {
        const qrCodeContainer = ref.current;
        htmlToImage.toBlob(qrCodeContainer)
            .then((blob) => {
                const item = new ClipboardItem({ 'image/png': blob });
                navigator.clipboard.write([item])
                    .then(() => {
                        // Копирование успешно выполнено
                        NotificationManager.success(Strings.notificationsCopyQRCode, Strings.notificationsSuccessTitle, 2500);
                    })
                    .catch((error) => {
                        // Обработка ошибки копирования
                        NotificationManager.success(Strings.notificationsCopyQRCode, Strings.notificationsSuccessTitle, 3500);
                        notificationError(error)
                    });
            })
            .catch((error) => {
                // Обработка ошибки создания изображения
                notificationError(error)
            });
    };


    const refMainContainer = useRef(null)

    const copyDataInBuffer = () => {
        navigator.clipboard.writeText(url)
        NotificationManager.success(Strings.notificationsCopyData, Strings.notificationsSuccessTitle, 2500);
    }



    return (
        <div className={classes.qrCodeGenerator} ref={refMainContainer}>
            <div className={classes.qrCodeContainer}>
                <div className={classes.qrCode} ref={ref} onClick={copyDataInBuffer} />
            </div>
            <h4>{`${qrCodeSize === -1 ? 300 : qrCodeSize} x ${qrCodeSize === -1 ? 300 : qrCodeSize} Px`}</h4>
            <div className={classes.eventContainer}>
                <div className={classes.downloadContainer} onClick={downloadQRCode}>
                    <div className={classes.buttonText}>Download</div>
                </div>
                <div className={classes.copyContainer} onClick={copyImageToClipboard}>
                    <div className={classes.buttonText}>Copy</div>
                </div>
            </div>
            <div className={classes.eventContainer}>
                {
                    useDeviceType() === 'desktop' &&
                    <div className={classes.downloadContainer} onClick={downloadParamsFile}>
                        <div className={classes.buttonText}>Download Params</div>
                    </div>
                }
                <div className={classes.downloadContainer}>
                    <CopyParamsButton pastParamsInBuffer={pastParamsInBuffer} copyParamsInBuffer={copyParamsInBuffer} />
                </div>
            </div>
            <Notifications />
        </div>
    );
}

export default QrGenerator;