// import Colors from 'values/Colors';
import QRCodeStyling from "qr-code-styling";

const QrCode = new QRCodeStyling({
    margin: 0,
    // cornersDotOptions: {
    //     type: "dot",
    //     gradient: {
    //         type: "linear",
    //         rotation: 150,
    //         colorStops: [
    //             {
    //                 offset: 0.3,
    //                 color: 'rgba(255,0,0,0.7)'
    //             },
    //             {
    //                 offset: 0.5,
    //                 color: 'rgba(0,255,0,0.7)'
    //             },
    //             {
    //                 offset: 1,
    //                 color: 'rgba(0,0,255,0.7)'
    //             }
    //         ]
    //     }
    // },
    // image: require('assets/icon/logo.svg'),
    // dotsOptions: {
    //     type: "rounded",
    // gradient: {
    // type: "linear",
    // rotation: 150,
    // colorStops: [
    //     {
    //         offset: 0.3,
    //         color: '#ff0000'
    //     },
    //     {
    //         offset: 0.5,
    //         color: '#00ff00'
    //     },
    //     {
    //         offset: 1,
    //         color: '#0000ff'
    //     }
    // ]
    // }
    // },
    // cornersSquareOptions: {
    //     type: "extra-rounded",
    //     gradient: {
    //         type: "linear",
    //         rotation: 180,
    //         colorStops: [
    //             {
    //                 offset: 0.15,
    //                 color: '#00ff00'
    //             },
    //             {
    //                 offset: 1,
    //                 color: '#00ff00'
    //             }
    //         ]
    //     }
    // },
    // imageOptions: {
    //     crossOrigin: "extra rounded",
    //     margin: 1,
    //     hideBackgroundDots: true,
    //     imageSize: 0.5,
    //     // crossOrigin: 'anonymous',
    //     // hideBackgroundDots: true,
    //     // imageSize: 0.25,
    //     // margin: 1
    // }

});

export default QrCode