import React from "react";
import { CgClipboard, CgCopy } from "react-icons/cg";
import classes from './CopyParamsButton.module.css'
import 'colors.css'

interface Props {
    copyParamsInBuffer?: (params: any) => void,
    pastParamsInBuffer?: (params: any) => void,
}

const CopyParamsButton = ({ copyParamsInBuffer, pastParamsInBuffer }: Props) => {
    return (
        <div className={classes.container}>
            <div className={classes.iconContainer}>
                <CgClipboard onClick={pastParamsInBuffer} />
                Past
            </div>
            <span className={classes.paramsText}>Params</span>
            <div className={classes.iconContainer}>
                <CgCopy onClick={copyParamsInBuffer} />
                Copy
            </div>
        </div>
    );
}

export default CopyParamsButton;