import React, { MutableRefObject, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import classes from './ColorPicker.module.css'
import { RgbaColorPicker } from "react-colorful";
import { CgColorBucket, CgMathPlus, CgClose } from "react-icons/cg";
import { SiLinear, SiTorbrowser } from "react-icons/si";
import { TbSquareRoundedFilled, TbSquareRounded, TbGridDots } from "react-icons/tb";
import ParamsContainer from "./ParamsContainer";
import Slider from "rc-slider";
import { HighContrast } from 'react-dial-knob'
import { vhwp, vwp } from 'values/Values'
import InputWithPlaceholder from "../input/InputWithPlaceholder";
import TypeOption from "../typeOption/TypeOption";
import DragAndDropContainer from "../dragAndDrop/DragAndDropContainer";
import { useDeviceType } from "components/hooks/useDeviceType";
import Formatter, { ColorRGBA } from "services/Formatter";

declare type ColorOptionChoice = "backgroundColor" | "squareColors" | "dotColors" | "dotsOptions"
interface Colors { (option: ColorRGBA): [{ rgba: ColorRGBA }] }
interface ColorPickerProps {
    defaultValue: Array<ColorRGBA>
    setValue: (params: any) => any
    value: any
    // valueKey: string
}

declare type TypeChoice = "dots" | "rounded"
declare type GradientType = "radial" | "linear";


const colorOption = {
    background: 'backgroundColor',
    square: 'squareColors',
    dot: 'dotColors',
    point: 'dotsOptions',
}

export interface HighContrastTheme {
    defaultColor?: Colors
    activeColor?: Colors
}


function getRGBAColor(array: Array<any>) {
    return array.map(item => {
        let matches = item.color.match(/rgba?\((\d+),(\d+),(\d+)(,([\d.]+))?\)/);
        return {
            r: parseInt(matches[1]),
            g: parseInt(matches[2]),
            b: parseInt(matches[3]),
            a: matches[5] ? parseFloat(matches[5]) : 1
        }
    })
}


// function ColorPicker({ defaultValue, setValue, value, setRef }: ColorPickerProps) {
const ColorPicker = forwardRef(({ defaultValue, setValue, value, }: ColorPickerProps, ref) => {
    const [colorOptionChoice, setColorOptionChoice] = useState(colorOption.background)
    const [currentIndex, setCurrentIndex] = useState({
        [colorOption.background]: 0,
        [colorOption.dot]: 0,
        [colorOption.point]: 0,
        [colorOption.square]: 0
    })


    const [color, setColor] = useState(null)

    const [hex, setHex] = useState(null)

    const [colorStops, setColorStops] = useState([])

    const initColors = () => {
        const backgroundColor = getRGBAColor(value.backgroundColor.gradient.colorStops)
        const dotColors = getRGBAColor(value.dotColors.gradient.colorStops)
        const dotsOptions = getRGBAColor(value.dotsOptions.gradient.colorStops)
        const squareColors = getRGBAColor(value.squareColors.gradient.colorStops)
        setColor({
            [colorOption.background]: [...backgroundColor],
            [colorOption.dot]: [...dotColors],
            [colorOption.point]: [...dotsOptions],
            [colorOption.square]: [...squareColors],
        })
        setHex({
            [colorOption.background]: backgroundColor.map(item => Formatter.rgbaToHex(item)),
            [colorOption.dot]: dotColors.map(item => Formatter.rgbaToHex(item)),
            [colorOption.point]: dotsOptions.map(item => Formatter.rgbaToHex(item)),
            [colorOption.square]: squareColors.map(item => Formatter.rgbaToHex(item)),
        })
    }


    useEffect(() => {
        initColors()
    }, [])

    useImperativeHandle(ref, () => ({
        initColors,
    }));

    const changeColor = (val, changeHex: boolean = true) => {
        const newColorStops = val && { ...value }
        const colorStops = val && newColorStops[colorOptionChoice].gradient.colorStops
        if (currentIndex[colorOptionChoice] < colorStops.length) {
            if (val) {
                colorStops[currentIndex[colorOptionChoice]].color = `rgba(${Object.values(val).join()})`
                setValue(newColorStops);
            }
            const oldColor = { ...color }
            oldColor[colorOptionChoice][currentIndex[colorOptionChoice]] = val
            if (changeHex) {
                setHex(old => {
                    const oldHexColor = { ...old }
                    oldHexColor[colorOptionChoice][currentIndex[colorOptionChoice]] = Formatter.rgbaToHex(val)
                    return oldHexColor
                })
            }
            setColor(oldColor)
        } else {
            // ? СЮДА ДОБАВИЬТ УВЕДОМЛЕНИЕ ОБ ОШИБКЕ
            console.log('СЮДА ДОБАВИЬТ УВЕДОМЛЕНИЕ ОБ ОШИБКЕ "не выбран цвет"')
        }
    }

    const changeHexColor = (hexa: string): void => {

        const oldColor = { ...hex }
        oldColor[colorOptionChoice][currentIndex[colorOptionChoice]] = hexa

        setHex(oldColor)
        if (/^#?[0-9A-Fa-f]{6}([0-9A-Fa-f]{2})?$/.test(hexa)) {
            // Удаляем символ # в начале строки, если он есть
            hexa = hexa.replace('#', '');

            // Проверяем, есть ли альфа-канал в строке HEX
            const hasAlpha = hexa.length === 8;

            // Разделяем строку на компоненты R, G, B и A
            let r = parseInt(hexa.substring(0, 2), 16);
            let g = parseInt(hexa.substring(2, 4), 16);
            let b = parseInt(hexa.substring(4, 6), 16);
            let a = hasAlpha ? parseInt(hexa.substring(6, 8), 16) / 255 : 1;

            // Вызываем функцию changeColor с компонентами RGBA
            changeColor({ r, g, b, a }, false);
        }
    }


    const gradientType = {
        get: value[colorOptionChoice].gradient.type,
        set: (newType: GradientType) => {
            const newValue = { ...value }
            newValue[colorOptionChoice].gradient.type = newType
            setValue(newValue)
        },
    }

    const gradientRotation = {
        get: value[colorOptionChoice].gradient.rotation,
        set: (newRotation: number) => {
            !newRotation && (newRotation = 0);
            const newValue = { ...value }
            newValue[colorOptionChoice].gradient.rotation = newRotation
            setValue(newValue)
        },
    }

    const paramsContainerProps = [
        { valueKey: colorOption.background, text: 'Background', Icon: CgColorBucket },
        { valueKey: colorOption.point, text: 'Point', Icon: TbGridDots },
        { valueKey: colorOption.dot, text: 'Dot', Icon: TbSquareRoundedFilled },
        { valueKey: colorOption.square, text: 'Square', Icon: TbSquareRounded },
    ]

    const gradientContainerProps = [
        { valueKey: 'linear', text: '\u00A0\u00A0\u00A0\u00A0\u00A0Linear\u00A0\u00A0\u00A0\u00A0\u00A0', Icon: SiLinear },
        { valueKey: 'radial', text: 'Ladial', Icon: SiTorbrowser },
    ]

    const horizontalScrollContainerRef = useRef(null);

    function newColor(): { newStringColor: string, newRgbaColor: ColorRGBA } {
        const colors = []
        for (let index = 0; index < 3; index++) {
            colors.push(Math.floor(Math.random() * 256))
        }
        return { newStringColor: `rgba(${colors.join(',')},1)`, newRgbaColor: { r: colors[0], g: colors[1], b: colors[2], a: 0.7 } }
    }

    const addColorStop = () => {
        const { newStringColor, newRgbaColor } = newColor()
        const oldValues = { ...value }
        const newIndex = oldValues[colorOptionChoice].gradient.colorStops.length
        oldValues[colorOptionChoice].gradient.colorStops[newIndex] = { offset: (1 / newIndex), color: newStringColor }
        const oldRGBAColor = { ...color }
        oldRGBAColor[colorOptionChoice][newIndex] = newRgbaColor
        setColor(oldRGBAColor)
        setHex(old => {
            const oldHexColor = { ...old }
            oldHexColor[colorOptionChoice][newIndex] = Formatter.rgbaToHex(newRgbaColor)
            return oldHexColor
        })
        setValue(oldValues)
        setCurrentIndex(old => ({ ...old, [colorOptionChoice]: colorStops.length - 1 }))
    }

    useEffect(() => {
        // Обновляем положение прокрутки после добавления новой остановки цвета
        const containerRef = horizontalScrollContainerRef.current;
        if (containerRef) {
            containerRef.scrollLeft = containerRef.scrollWidth - containerRef.clientWidth;
        }
    }, [value]);

    const dellColorStop = (index) => {
        const oldValues = { ...value }
        const oldColor = { ...color }
        oldColor[colorOptionChoice].splice(index, 1)
        oldValues[colorOptionChoice].gradient.colorStops.splice(index, 1)
        setColor(oldColor)
        setValue(oldValues);
    }

    const onChangeSlider = (val) => {
        const oldValues = { ...value }
        oldValues[colorOptionChoice].gradient.colorStops.forEach((item, i) => (item.offset = parseFloat((val[i] / 100).toString())))
        setValue(oldValues)
    }

    useEffect(() => {
        setColorStops(value[colorOptionChoice].gradient.colorStops)
    }, [value, colorOptionChoice])


    const getGradientForSlider = () => {
        if (colorStops.length > 0) {
            let trackGradients = []
            colorStops.forEach((item, index) => {
                let gradient = `linear-gradient(90deg, ${item.color} ${item.offset * 100}%`;
                (index + 1 < colorStops.length) && (gradient += `, ${colorStops[index + 1].color} ${colorStops[index + 1].offset * 100}%`)
                gradient += ')'
                trackGradients.push({ background: gradient })
            })
            const railGradient = { background: `linear-gradient(90deg, ${colorStops[0].color} ${colorStops[0].offset * 100}%, ${colorStops[colorStops.length - 1].color} ${colorStops[colorStops.length - 1].offset * 100}%)` }
            return { track: trackGradients, rail: railGradient }
        }
        return { track: { background: 'var(--color-gray)' }, rail: { background: 'var(--color-white)' } }
    }



    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div className={classes.rowContainer}>
                    <div className={classes.iconContainer}>
                        {paramsContainerProps.map((item, index) => <ParamsContainer key={index}
                            {...item} value={colorOptionChoice} setValue={setColorOptionChoice} />)}
                    </div>
                    <div className={classes.colorContainer}>
                        <section className="custom-layout example">
                            {color && <RgbaColorPicker color={color[colorOptionChoice][currentIndex[colorOptionChoice]]} onChange={changeColor} />}
                        </section>
                        <div className={classes.colorOptionChoiceWrapper}>
                            {color && color[colorOptionChoice][currentIndex[colorOptionChoice]] && <input className={classes.colorPickerInput} type="text" placeholder="R"
                                value={color[colorOptionChoice][currentIndex[colorOptionChoice]].r} onChange={e => changeColor({
                                    ...color[colorOptionChoice][currentIndex[colorOptionChoice]], r: e.target.value
                                })} />}
                            {color && color[colorOptionChoice][currentIndex[colorOptionChoice]] && <input className={classes.colorPickerInput} type="text" placeholder="G"
                                value={color[colorOptionChoice][currentIndex[colorOptionChoice]].g} onChange={e => changeColor({
                                    ...color[colorOptionChoice][currentIndex[colorOptionChoice]], g: e.target.value
                                })} />}
                            {color && color[colorOptionChoice][currentIndex[colorOptionChoice]] && <input className={classes.colorPickerInput} type="text" placeholder="B"
                                value={color[colorOptionChoice][currentIndex[colorOptionChoice]].b} onChange={e => changeColor({
                                    ...color[colorOptionChoice][currentIndex[colorOptionChoice]], b: e.target.value
                                })} />}
                            {color && color[colorOptionChoice][currentIndex[colorOptionChoice]] && <input className={classes.colorPickerInput} type="text" placeholder="A"
                                value={color[colorOptionChoice][currentIndex[colorOptionChoice]].a} onChange={e => changeColor({
                                    ...color[colorOptionChoice][currentIndex[colorOptionChoice]], a: e.target.value
                                })} />}
                        </div>
                        {color && color[colorOptionChoice][currentIndex[colorOptionChoice]] && <input className={classes.colorHexPickerInput} type="text" placeholder="FF0000"
                            value={hex[colorOptionChoice][currentIndex[colorOptionChoice]]} onChange={e => changeHexColor(e.target.value)} />}

                        <div className={classes.colorStopsContainer}>
                            <div className={classes.horizontalScrollContainer} ref={horizontalScrollContainerRef}>
                                {
                                    colorStops.map((item, index) => {
                                        return (
                                            <div className={classes.colorBlock} key={index}
                                                onClick={() => setCurrentIndex(old => ({ ...old, [colorOptionChoice]: index }))}
                                                style={{
                                                    backgroundColor: item.color,
                                                    // borderStyle: (index === currentIndex) ? 'inset' : 'solid',
                                                    borderColor: (index === currentIndex[colorOptionChoice]) ? 'SteelBlue' : 'var(--color-black)'
                                                }}>
                                                <CgClose style={{ color: 'white', mixBlendMode: 'difference' }} onClick={() => dellColorStop(index)} />
                                            </div>
                                        )
                                    })
                                }
                                <div className={classes.colorBlock}
                                    onClick={addColorStop}>
                                    <CgMathPlus />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.rowContainer}>
                    <div className={classes.iconContainer} style={{ marginLeft: '1.5vw' }}>
                        {gradientContainerProps.map((item, index) => <ParamsContainer key={index}
                            {...item} value={gradientType.get} setValue={gradientType.set} />)}
                        <div className={classes.paramsContainer}>
                            <HighContrast
                                style={{
                                    display: 'grid',
                                    alignContent: 'center',
                                    justifyItems: 'center',
                                }}
                                diameter={useDeviceType() === 'phone' ? vhwp(20) : vhwp(65)}
                                min={0}
                                max={360}
                                step={1}
                                value={gradientRotation.get}
                                theme={{
                                    defaultColor: 'var(--color-black)',
                                    activeColor: 'SteelBlue'
                                }}
                                onValueChange={gradientRotation.set}
                                ariaLabelledBy={'my-label'}
                            >
                                {/* <input className={classes.highContrastInput} value={gradientRotation.get} /> */}
                                {/* <label id={'my-label'}>Some label</label> */}
                                <InputWithPlaceholder containerStyle={{
                                    fontSize: `${useDeviceType() === 'phone' ? '100%' : '70%'}`, width: `${useDeviceType() === 'phone' ? '7vw' : '3.5vw'}`,
                                    marginTop: '0.3vh',
                                }}
                                    inputStyle={{
                                        fontWeight: '700',
                                        color: 'var(--color-black)',
                                        textAlign: 'center',
                                        backgroundColor: 'var(--color-gray)',
                                    }}
                                    value={gradientRotation.get} setValue={gradientRotation.set}
                                    placeholder={'Degree'}
                                />
                            </HighContrast>
                        </div>
                    </div>
                    <DragAndDropContainer value={value} setValue={setValue} />
                </div>
            </div>
            {
                colorStops.length > 1 && <Slider
                    value={colorStops.map(item => item.offset * 100)}
                    onChange={onChangeSlider}
                    min={0}
                    max={100}
                    style={{ margin: '0.5% 0.7% 0.2% -0.7%' }}
                    trackStyle={getGradientForSlider().track}
                    // полоска после
                    railStyle={getGradientForSlider().rail}
                    // ручка
                    range
                    handleStyle={{
                        backgroundColor: 'var(--color-black)', width: '2.5vh', height: '2.5vh', marginTop: '-1.1vh', opacity: '0.9'
                    }}
                />
            }
        </div>
    );
})

export default ColorPicker;