// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoContainer_h1__XYZ2g {\n    font-weight: 600;\n    /* font-size: 3vw; */\n    font-size: 285%;\n}\n\n.InfoContainer_h2__rUHdB {\n    font-weight: 400;\n    /* font-size: 1.5vw; */\n    font-size: 145%;\n}\n\n@media (max-width: 770px) {\n    .InfoContainer_h1__XYZ2g {\n        /* font-size: 12vw; */\n        /* font-size: 285%; */\n        font-weight: 600;\n        margin: 0;\n    }\n\n    .InfoContainer_h2__rUHdB {\n        /* font-size: 6vw; */\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/UI/info/InfoContainer.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI;QACI,qBAAqB;QACrB,qBAAqB;QACrB,gBAAgB;QAChB,SAAS;IACb;;IAEA;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".h1 {\n    font-weight: 600;\n    /* font-size: 3vw; */\n    font-size: 285%;\n}\n\n.h2 {\n    font-weight: 400;\n    /* font-size: 1.5vw; */\n    font-size: 145%;\n}\n\n@media (max-width: 770px) {\n    .h1 {\n        /* font-size: 12vw; */\n        /* font-size: 285%; */\n        font-weight: 600;\n        margin: 0;\n    }\n\n    .h2 {\n        /* font-size: 6vw; */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h1": "InfoContainer_h1__XYZ2g",
	"h2": "InfoContainer_h2__rUHdB"
};
export default ___CSS_LOADER_EXPORT___;
