import React, { useState } from "react";
import classes from './ColorPicker.module.css'
import { vhwp } from "values/Values";


interface Props {
    value: string
    setValue: (params: any) => any
    valueKey: string,
    text: string,
    Icon: React.ElementType,
}


function ParamsContainer({ value, setValue, valueKey, text, Icon }: Props) {

    const changeValue = () => {
        setValue(valueKey)
    }

    return (
        <div className={classes.paramsContainer}
            onClick={changeValue}
        >
            <Icon color={(value === valueKey) ? "SteelBlue" : 'var(--color-black)'} className={classes.icon} />
            <h4 style={{ color: (value === valueKey) ? "SteelBlue" : 'var(--color-black)' }}>{text}</h4>
        </div>
    );
}

export default ParamsContainer;