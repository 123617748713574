import axios from "axios";
import env from '../../package.json'
import $api from "http";
import { publicDecrypt } from "crypto-browserify";
const { Buffer } = require('buffer');

export default class OAuthService {

    static async yandexGetToken(code) {
        const response = await axios.post(`${env.nodeHost}/api/oauth/yandex`, { code }, { ...env.axiosConfig.json, withCredentials: true })
        return response.data
    }

    static async vkGetToken(code) {
        console.log('vk Code ', code)
        // const response = await axios.post(`${env.nodeHost}/api/oauth/vk`, { code }, {...env.axiosConfig.json, withCredentials: true})
        const response = await axios.get(`https://oauth.vk.com/access_token?client_id=${env.vkAPI.clientID}&client_secret=${env.vkAPI.sercetID}&redirect_uri=${env.redirectUri}&code=${code}`, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
        console.log('response.data ', response.data)
        return response.data
    }

    static async googleGetToken(code) {
        const response = await axios.post(`${env.nodeHost}/api/oauth/google`, { code }, { ...env.axiosConfig.json, withCredentials: true })
        return response.data
    }

    static async refreshAuth() {
        const response = await $api.post(`${env.nodeHost}/api/oauth/refresh`, {}, { withCredentials: true })
        return response.data
    }

    static async checkAuth() {
        const response = await $api.post(`${env.nodeHost}/api/oauth/check`, {}, { withCredentials: true })
        return response.data
    }

    static async logout() {
        const response = await axios.post(`${env.nodeHost}/api/oauth/logout`, {}, { withCredentials: true })
        return response.data
    }

    static async decrypted(key) {
        try {
            const decrypted = await publicDecrypt(env.secretKeys.publicKey, Buffer.from(localStorage.getItem(key), 'base64'))
            return JSON.parse(decrypted.toString('utf-8'))
        } catch (error) {
            localStorage.removeItem(key)
            return false
        }
    }
    static decryptedSync(key) {
        try {
            const decrypted = publicDecrypt(env.secretKeys.publicKey, Buffer.from(localStorage.getItem(key), 'base64'))
            return JSON.parse(decrypted.toString('utf-8'))
        } catch (error) {
            localStorage.removeItem(key)
            return false
        }
    }



    // async yandexGetToken(code) {
    //     return await axios.post('https://oauth.yandex.ru/token', {
    //         grant_type: 'authorization_code',
    //         code: code,
    //         client_id: env.yandexAPI.clientID,
    //         client_secret: env.yandexAPI.sercetID
    //     }, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         },
    //     })
    // }


    // static async yandexGetUserInfo(code) {
    //     const data = await this.yandexGetToken(code)
    //     const userInfoResponse = await axios.get("https://login.yandex.ru/info?format=json&jwt_secret=4af8c4a93d0045e68578ab37e3e13e3c&with_openid_identity=1&oauth_token=" + data.access_token)
    //     // Отправка на сервер
    //     // userInfoResponse
    // }

    // static async vkGetToken(code) {
    //     return await axios.get(`https://oauth.vk.com/access_token?client_id=${env.vkAPI.clientID}&client_secret=${env.vkAPI.sercetID}&redirect_uri=${env.redirectUri}&code=${code}`, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         },
    //     })
    // }

    // static async vkGetUserInfo(code) {
    //     // const data = await this.vkGetToken(code)
    //     // console.log('data VK ', data)
    //     const userInfoResponse = await axios.get(`https://api.vk.com/method/users.get?v=5.92&access_token=${code}&fields=screen_name`)
    //     // Отправка на сервер
    //     // userInfoResponse
    //     console.log('userInfoResponse ', userInfoResponse)
    // }


    // static async googleAuth(response) {
    //     // ! Изиенить лого согласно требованиям 
    //     let userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
    //         method: 'GET',
    //         headers: {
    //             Accept: 'application/json',
    //             Authorization: `Bearer ${response.access_token} `,
    //             'Content-Type': 'application/json'
    //         },
    //     })
    //     const data = await userInfoResponse.json()
    //     // fetch(`https://people.googleapis.com/v1/people/${data.id}?personFields=birthdays&access_token=${response.access_token}`, {
    //     //     method: 'GET',
    //     //     headers: {
    //     //         Accept: 'application/json',
    //     //         Authorization: `Bearer ${response.access_token} `,
    //     //         'Content-Type': 'application/json'
    //     //     },
    //     // }).then(info => {
    //     //     console.log('info')
    //     //     console.log(info)
    //     // })
    //     console.log(data)
    // }

}