import React, { useRef, useState } from 'react'
import InfoContainer from 'components/UI/info/InfoContainer';
import InputWithPlaceholder from 'components/UI/main/input/InputWithPlaceholder';
import 'styles/css/main.css'
import 'styles/css/general.css'
import 'values.css'
import OptionTitle from 'components/UI/main/option/title/OptionTitle';
import DropDownContainer from 'components/UI/main/dropDown/DropDownContainer';
import QrGenerator from 'components/UI/qrCode/qrGenerator/QrGenerator';
import ColorPicker from 'components/UI/main/color/ColorPicker';
import TypeOption from 'components/UI/main/typeOption/TypeOption';
import Notifications from "components/UI/popupNotifications/Notifications";
import { NotificationManager } from 'react-notifications';
import Strings from 'values/Strings';


export declare type DotsType = "dots" | "rounded" | "classy" | "classy-rounded" | "square" | "extra-rounded";
export declare type DotType = "dot" | "square" | "extra-rounded" | "none";
export declare type SquareType = "dot" | "square" | "extra-rounded" | "none";
declare type OpenAndCloseTypes = 'type' | 'colors'

function downloadJSONFile(data, filename) {
    const jsonData = JSON.stringify(data, null, 2); // Преобразуем данные в строку JSON с отступами

    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();

    URL.revokeObjectURL(url); // Очищаем ссылку после скачивания
}

function MainPage() {

    const [value, setValue] = useState({
        // shape: 'square',
        // shape: 'circle',
        image: null,
        url: '',
        qrCodeSize: -1,
        backgroundColor: {
            type: "rounded",
            gradient: {
                type: "linear",
                rotation: 150,
                colorStops: [
                    {
                        offset: 0.3,
                        color: 'rgba(255,0,0,0)'
                    },
                ]
            }
        },
        dotsOptions: {
            type: "classy-rounded",
            gradient: {
                type: "linear",
                rotation: 150,
                colorStops: [
                    {
                        offset: 1,
                        color: 'rgba(0,0,0,0.75)'
                    }
                ]
            }
        },
        squareColors: {
            type: "extra-rounded",
            gradient: {
                type: "linear",
                rotation: 150,
                colorStops: [
                    {
                        offset: 1,
                        color: 'rgba(0,0,0,0.75)'
                    }
                ]
            }
        },
        dotColors: {
            type: "none",
            gradient: {
                type: "linear",
                rotation: 150,
                colorStops: [
                    {
                        offset: 1,
                        color: 'rgba(0,0,0,0.75)'
                    }
                ]
            }
        },
        imageOptions: {
            crossOrigin: "extra rounded",
            margin: 1,
            hideBackgroundDots: true,
            imageSize: 0.5,
        },
        pointColors: '',
    })

    const [refColorsOptions, setRefColorsOptions] = useState(null)
    const [refTypeOptions, setRefTypeOptions] = useState(null)
    const [colorsOptionsIsOpen, setColorsOptionsIsOpen] = useState(true)
    const [typeOptionsIsOpen, setTypeOptionsIsOpen] = useState(true)
    // const openAndCloseColorsOptions = () => {
    //     if (colorsOptionsIsOpen) {
    //         setColorsOptionsIsOpen(false)
    //         refColorsOptions.current.className = 'dropDownContainer open'
    //     } else {
    //         setColorsOptionsIsOpen(true)
    //         refColorsOptions.current.className = 'dropDownContainer'
    //     }
    // }
    const openAndCloseOptions = (type: OpenAndCloseTypes): void => {
        refTypeOptions.current.className = 'dropDownContainer'
        refColorsOptions.current.className = 'dropDownContainer'

        if (type === 'type') {
            refTypeOptions.current.className = 'dropDownContainer open'
        }
        if (type === 'colors') {
            refColorsOptions.current.className = 'dropDownContainer open'
        }
    }

    const mainParamsProps = {
        data: { placeholder: 'Your URL', example: 'https://www.QRTrace.ru', valueKey: 'url', value: value, setValue: setValue },
        size: { placeholder: 'Qr Code Size', example: '300', valueKey: 'qrCodeSize', value: value, setValue: setValue, slider: true },
    }

    const copyParamsInBuffer = () => {
        navigator.clipboard.writeText(JSON.stringify(value))
        NotificationManager.success(Strings.notificationsCopyData, Strings.notificationsSuccessTitle, 2500);
    }


    const colorPickerRef = useRef(null);
    const pastParamsInBuffer = () => {
        navigator.clipboard.readText()
            .then((params) => {
                setValue(JSON.parse(params))
                if (colorPickerRef.current) {
                    colorPickerRef.current.initColors();
                }
                NotificationManager.success(Strings.notificationsClipboardData, Strings.notificationsSuccessTitle, 2500);
            }).catch((err) => {
                console.error('Failed to read clipboard data: ', err);
            });
    }

    const downloadParamsFile = () => {
        downloadJSONFile(value, `${value.url}-QRT.json`)
    }


    return (
        <div className='mainWrapper'>
            <div className="infoContainer">
                <InfoContainer />
            </div>
            <div className="mainContent">
                <div className="optionsContainer">
                    <OptionTitle title='Main Options' />
                    <div className="mainParams">
                        <InputWithPlaceholder marginTop={false} {...mainParamsProps.data} />
                        <InputWithPlaceholder {...mainParamsProps.size} />
                    </div>
                    <OptionTitle title='Colors and Logo Options' onClick={() => openAndCloseOptions('colors')} />
                    <DropDownContainer setRefMainOptions={setRefColorsOptions}>
                        <ColorPicker ref={colorPickerRef} defaultValue={[
                            { r: 255, g: 0, b: 0, a: 0.7 },
                            { r: 0, g: 255, b: 0, a: 0.7 },
                            { r: 0, g: 0, b: 255, a: 0.7 }
                        ]} value={value} setValue={setValue} />
                    </DropDownContainer>
                    <OptionTitle title='Type Options' onClick={() => openAndCloseOptions('type')} />
                    <DropDownContainer setRefMainOptions={setRefTypeOptions}>
                        <TypeOption value={value} setValue={setValue} />
                    </DropDownContainer>
                </div>
                <div className="fixedContainer">
                    <QrGenerator
                        copyParamsInBuffer={copyParamsInBuffer}
                        pastParamsInBuffer={pastParamsInBuffer}
                        downloadParamsFile={downloadParamsFile}
                        {...value} />
                </div>
            </div>
            <Notifications />
        </div >
    );
}

export default MainPage;